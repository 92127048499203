.l-header__main, .l-header__top {

    .icon-down-arrow {
        width: 10px;
        height: 10px;
        fill: currentColor;
        margin-left: 4px;
    }

    // .dropdown-toggle:after {
    //     display: inline-block;
    //     width: 0;
    //     height: 0;
    //     margin-left: .255em;
    //     vertical-align: .255em;
    //     content: "";
    //     border-top: .3em solid;
    //     border-right: .3em solid transparent;
    //     border-bottom: 0;
    //     border-left: .3em solid transparent;
    // }

    .dropdown-toggle:after {
        content: none;
    }

    // .dropdown-toggle {
    //     &::after {
    //         font-family: aha-icon-font!important;
    //         speak: none;
    //         font-style: normal;
    //         font-weight: 400;
    //         font-variant: normal;
    //         text-transform: none;
    //         line-height: 1;
    //         -webkit-font-smoothing: antialiased;  
    //     }
    // }

    
        // .c-top-nav__link.dropdown-toggle {
        //     padding-right: 25px;
        // }
   
}
.c-cta {
    font-weight: 600;
    position: relative;

    &--icon {
        align-items: center;
        display: flex;

        img {
            max-width: 20px;
            max-height: 20px;
            margin-right: 10px;
        }
    }
}
/* OneTrust Cookie Link */
footer .l-footer__supplemental-nav a.ot-sdk-settings-link {
	color: black !important;
	border: 0px !important;
	padding: 0px !important;
}

footer .l-footer__supplemental-nav a.ot-sdk-settings-link:hover {
	background-color: transparent !important;
	text-decoration: underline !important;
}
.l-header {
    &__top {
        padding-top: 30px;
        padding-bottom: 21.875px;
        @include media-breakpoint-up(md) {
            padding: 45px 0px;
        }

        border-bottom: 1px solid #e3e3e3;
    }

    .nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .c-top-nav {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    
        @include media-breakpoint-up(md) {
            padding-left: 75px;
        }
    
        &__logo {
            position: absolute;

            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                /* IE10+ CSS styles go here */
                top: -24px;
            }
    
            @include media-breakpoint-up(md) {
                left: 15px;
                margin-left: 0;
            }
    
            img {
                max-width: 100%;
                height: 50px;
    
                @include media-breakpoint-up(md) {
                    height: 90px;
                }
            }
        }
    
        &__nav {
            margin-right: -.625rem;
            margin-left: auto; 
            display: flex;
            flex-wrap: wrap;
            padding-left: 0;
            margin-bottom: 0;
            list-style: none;
        }
    
        
    
        .c-top-nav__button {
            border-radius: 20px;
            background-color: #c10e21;
            border-color: #c10e21;
        }
    
        &__link {
            display: block;
            margin: 10px 10px 8px 10px;
            padding: 0;
            font-weight: 400;
            background: transparent;
            border: none;
            cursor: pointer;
            color: #495057;
            position: relative;
    
            &:focus {
                color: $red;
                background-color: transparent;
            }
    
            img {
                max-width: 22px;
                top: -.8px;
                position: relative;
            }
        }

        &__menu-trigger {
            height: 25px;
            width: 42px;
            border: none;
            border-radius: 5px;
            background: transparent;
            position: relative;
            top: 4px;
            display: inline-block;
            margin-left: 10px;
            
            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        &__hamburger {
            width: 30px;
            height: 2px;
            position: relative;
            top: -8px;
            background-color: $black;
            display: block;

            &::before, &::after {
                content: "";
                width: 100%;
                height: 2px;
                background-color: $black;
                position: absolute;
                border-radius: 5px;
                transition: all .2s linear;
            }

            &::before {
                top: 16px;
                left: 0;
                width: 26px;
                transition: all .2s linear;
            }

            &::after {
                top: 8px;
                left: 0;
                width: 18px;
            }
        }
    
        &__item {
            border-right: 0;
            padding: 0 5px;
            display: none;
            align-items: center;
    
            @include media-breakpoint-up(md) {
                display: flex;
            }
    
            .dropdown-toggle {
                // padding-right: 25px;
            }
        }
    
    }
}

// Mobile nav opened
body.modal-open--mobile-nav {
    .c-top-nav {
        &__hamburger {
            background: none;
            
            &::before {
                width: 100%;
                transform: rotate(-45deg) translate(50%);
                transform-origin: 10px 21px;
            }

            &::after {
                width: 100%;
                transform: rotate(45deg);
                transform-origin: 13px -5px;
            }
        }
    }
}